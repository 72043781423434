/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';

import Cookies from 'js-cookie';

// import {
//   FaWhatsapp,
//   FaTelegramPlane,
//   FaFacebookMessenger,
// } from 'react-icons/fa';

import { toast } from 'react-toastify';
// import api from '~/services/api';
import LoaderComponent from '~/components/Loader';
import { whatsappAddEvent } from '~/functions/events';
import { CheckIfHaveNewFbc, ConversionApiMeta } from '~/functions/meta';
import { ConversionApiTiktok } from '~/functions/tiktok';
import {
  Container,
  DivHeader,
  Div,
  DivButtons,
  DivInput,
  // SpanError,
} from './styles';

import WhatsAppIcon from '../../../../assets/icons/whatsapp.svg';

import backBlackButton from '../../../../assets/icons/backBlackButton.svg';

import { updateProfileRequest } from '../../../../store/modules/user/actions';

export default function CustomizeName() {
  const [callButton] = useState('whatsapp');
  const [text, setText] = useState();
  const [inputFocus, setInputFocus] = useState(false);
  const [loadButton, setLoadButton] = useState(false);

  const history = useHistory();

  const dispatch = useDispatch();

  const { id, email, username, whatsapp } = useSelector(
    state => state.user.profile
  );

  useEffect(() => {
    const string =
      whatsapp &&
      whatsapp.substring(
        whatsapp.length === 13 ? whatsapp.length - 11 : whatsapp.length - 10
      );
    setText(string);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // async function sendWelcomeMessage(data) {
  //   if (data) {
  //     const payload = {
  //       to: data,
  //       template: 'welcome',
  //     };
  //     await api.post('/send-whatsapp-message', payload);

  //     return true;
  //   }
  //   return false;
  // }

  async function handleSubmit() {
    try {
      setLoadButton(true);

      if (callButton === 'whatsapp') {
        // eslint-disable-next-line no-restricted-globals
        const isNotNumber = isNaN(text);

        if (isNotNumber || text.trim().length < 10) {
          toast.error('Digite um whatsapp válido Ex: 51999999999');
          return;
        }
      }

      const data = {
        user: username,
        whatsapp: `55${text}`,
        email,
      };

      dispatch(updateProfileRequest(data));

      whatsappAddEvent();

      const uuid = uuidv4();

      // const fbp = Cookies.get('_fbp');
      // const fbc = Cookies.get('_fbc');
      const fbc = await CheckIfHaveNewFbc({ fbc: Cookies.get('_fbc') });

      try {
        ConversionApiMeta({
          eventId: uuid,
          eventName: 'CompleteRegistration',
          email,
          phone: `55${text}`,
          externalId: id,
          fbc,
        });
      } catch (err) {
        console.log(
          'Erro ao enviar evento CompleteRegistration na criação de conta Meta',
          err
        );
      }

      try {
        ConversionApiTiktok({
          eventId: uuid,
          eventName: 'Subscribe',
          email,
          phone: `55${text}`,
          externalId: id,
        });
      } catch (err) {
        console.log(
          'Erro ao enviar evento Subscribe na criação de conta Tiktok',
          err
        );
      }

      // sendWelcomeMessage(data.whatsapp);

      // history.push(`/responsivecustomizecategory`);
      // history.push('/responsivecustomizeplan');

      setTimeout(() => {
        history.push('/');
        setLoadButton(false);
      }, 2000);
    } catch (err) {
      console.log(err);
      setLoadButton(false);
    }
  }

  function inputValidate(value) {
    const newText = value && value.replace(/[^0-9]+/g, '');

    if (newText && newText.charAt(0) === '0') {
      const hasZero = newText.substr(1);
      setText(hasZero);
    } else {
      setText(newText);
    }
  }

  // useEffect(() => {
  //   const newText = text && text.replace(/[^0-9]+/g, '');

  //   if (newText && newText.charAt(0) === '0') {
  //     const hasZero = newText.substr(1);
  //     setText(hasZero);
  //   } else {
  //     setText(newText);
  //   }
  // }, [text]);

  return (
    <Container>
      <DivHeader>
        <div className="div-space">
          <Link to="/customizeavatar">
            <img src={backBlackButton} alt="back-button" />
          </Link>
        </div>
        <div className="div-text">{/* <h1>Mensagem</h1> */}</div>
        <div className="div-space" />
      </DivHeader>
      <Div>
        <img src={WhatsAppIcon} alt="whatsapp" />
        <strong>Adicionar Whatsapp</strong>

        <span>
          Através desse número seus clientes entrarão em contato com você e
          farão seus pedidos
        </span>

        <span className="span-whats">Número do whatsapp</span>

        <DivInput inFocus={inputFocus}>
          <div>
            <span>+55</span>
          </div>

          <input
            type="tel"
            placeholder="51999999999"
            value={text}
            onChange={e => inputValidate(e.target.value)}
            maxLength={11}
            onFocus={() => setInputFocus(true)}
            onBlur={() => setInputFocus(false)}
          />
        </DivInput>
        {/* <SpanError>a</SpanError> */}

        <DivButtons block={!(text && text.length >= 10) || loadButton}>
          <button
            className="button-continue"
            type="button"
            onClick={handleSubmit}
            disabled={!(text && text.length >= 10) || loadButton}
          >
            {loadButton ? <LoaderComponent size={20} white /> : 'Continuar'}
          </button>

          {/* <Link to="/">
            <button className="button-jump" type="button">
              Pular essa etapa
            </button>
          </Link> */}
        </DivButtons>
      </Div>
    </Container>
  );
}
